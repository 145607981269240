<template>
    <div>
      <CModal
        title="ERROR"
        color="primary"
        :show.sync="showModal"
      >
      <span class="d-none">{{ errorCode ? errorCode : '' }} </span>
      Ha ocurrido un error, inténtalo de nuevo más tarde o ponte en contacto con el administrador.<br><br>
      Ocorreu um erro, tente novamente mais tarde ou contacte o administrador.
      <template #footer>
                <CButton @click="showModal = false" color="danger">OK</CButton>
            </template>
      </CModal>
    </div>
  </template>

<script>


export default {
  name: "ErrorModal",
  data () {
    return {
      showModal: false,
    }
  },
  computed: {
    errorCode() {
      const error = this.$store.getters.getError;
      this.showError();
      return error;
    },
  },
  methods: {
    showError() {
      this.showModal =  this.$store.getters.getError ? true : false;
    }
  },
  watch: {
    showModal(newValue) {
      if (!newValue) {
        //Clear Error
        this.$store.dispatch("saveError", null);
      }
    },
  },
}
</script>