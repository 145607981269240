import "core-js/stable";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import CoreuiVueCharts from "@coreui/vue-chartjs";
import store from "./store/index";
// import { iconsSet as icons } from "./assets/icons/icons.js";
// import store from "./store";
import {
  cilAccountLogout,
  cilGauge,
  cilBook,
  cilMoney,
  cilVolumeHigh,
  cilShieldAlt,
  cilSettings,
  cilSmile,
  cilSad,
  cilBarChart,
  cilTrash,
  cilUserPlus,
  cilUserX,
  cilSave,
  cilXCircle,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilDataTransferDown,
  cilArrowThickBottom,
  cilArrowBottom,
  cilReload,
  cilArrowThickFromBottom,
} from "@coreui/icons";

// Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(CoreuiVueCharts);
Vue.prototype.$log = console.log.bind(console);

/**
 * Vue prototype encodeForm
 * @param {*} data Form data
 * @returns an form data parameters encoded including Base64 encoiding for password
 */
Vue.prototype.$encodeForm = (data) => {
  if (typeof data.password !== "undefined")
    data.password = window.btoa(data.password);
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

/**
 * Vue Print plugin
 */
import Print from "vue-print-plugin";
Vue.use(Print);

/**
 * Vue GTM Plugin
 */
import VueGtm from "@gtm-support/vue2-gtm";
Vue.use(VueGtm, {
  id: "GTM-T68GHSD",
  enabled: true,
  debug: true,
});

/**
 * Vue Pikaday plugin
 */
import VuePikaday from "@netcz/vue-pikaday";
Vue.use(VuePikaday);

/**
 * Vue App instance
 */
new Vue({
  el: "#app",
  router,
  store,
  icons: {
    cilAccountLogout,
    cilGauge,
    cilBook,
    cilMoney,
    cilVolumeHigh,
    cilShieldAlt,
    cilSettings,
    cilSmile,
    cilSad,
    cilBarChart,
    cilTrash,
    cilUserPlus,
    cilUserX,
    cilSave,
    cilXCircle,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilDataTransferDown,
    cilArrowThickBottom,
    cilArrowBottom,
    cilReload,
    cilArrowThickFromBottom,
  },
  template: "<App/>",
  components: {
    App,
  },
});
