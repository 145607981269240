import Vue from "vue";
import Vuex from "vuex";

import { concessions } from "@/store/concessions";
import emptyObject from "@/utils/emptyObject";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    token: null,
    currentLang: null,
    langMessages: {},
    sidebarShow: "responsive",
    sidebarMinimize: false,
    filterAreaValue: null,
    // This property must have a string value due to CSelect component
    filterAreaCategory: "null",
    FILTER_AREA_CATEGORIES: ["countries", "regionals", "aspms", "concessions"],
    FILTER_AREA_PROP_CODES: {
      countries: "countryCode",
      regionals: "regionalCode",
      aspms: "aspm",
      concessions: "concessionCode",
    },
    filterAreaCategoriesResult: null,
    filtersAreaComboParams: null,
    filterFYValue: null,
    filterMonthValue: null,
    filterIsCumulativeValue: null,
    filterMonthSinceValue: null,
    filterMonthUpToValue: null,
    filterCampaignValue: null,
    filterModelValue: null,
    filterSinceDateValue: null,
    filterUpToDateValue: null,
    filterAspmSpmValue: null,
    filterTypeOfContractValue: null,
    filterNewCarSalesValue: null,
    filterModelOrDcValue: null,
    excelButtonStatus: false,
    currentAreaParams: null,
    filterServiceType: null,
    filterOrder: null,
    filterAmountToShow: null,
    filterReportValue: null,
    error: null,
    filterOrderNumberValue: null,
  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.user = user;
    },
    SET_CURRENT_TOKEN(state, token) {
      state.token = token;
    },
    SET_CURRENT_LANG(state, lang) {
      state.currentLang = lang;
    },
    SET_LANG_MESSAGES(state, langMessages) {
      state.langMessages = langMessages;
    },
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarOpened ? false : "responsive";
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarClosed ? true : "responsive";
    },
    set(state, [variable, value]) {
      state[variable] = value;
    },
    SET_FILTER_AREA_VALUE(state, value) {
      state.filterAreaValue = value;
    },
    SET_FILTER_AREA_CATEGORY(state, category) {
      state.filterAreaCategory = category;
      state.filterAreaCategoriesResult = state.FILTER_AREA_CATEGORIES.filter(
        (elem) => elem !== category
      );
    },
    SET_FILTER_FY_VALUE(state, value) {
      state.filterFYValue = value;
    },
    SET_FILTER_MONTH_VALUE(state, value) {
      state.filterMonthValue = value;
    },
    SET_FILTER_IS_CUMULATIVE_VALUE(state, value) {
      state.filterIsCumulativeValue = parseInt(value);
    },
    SET_FILTER_MONTH_SINCE_VALUE(state, value) {
      state.filterMonthSinceValue = value;
    },
    SET_FILTER_MONTH_UP_TO_VALUE(state, value) {
      state.filterMonthUpToValue = value;
    },
    SET_FILTER_CAMPAIGN(state, value) {
      state.filterCampaignValue = value;
    },
    SET_FILTER_MODEL(state, value) {
      state.filterModelValue = value;
    },
    SET_FILTER_SINCE_DATE(state, value) {
      state.filterSinceDateValue = value;
    },
    SET_FILTER_UP_TO_DATE(state, value) {
      state.filterUpToDateValue = value;
    },
    SET_FILTER_ASPM_SPM(state, value) {
      state.filterAspmSpmValue = value;
    },
    SET_FILTER_TYPE_OF_CONTRACT(state, value) {
      state.filterTypeOfContractValue = value;
    },
    SET_FILTER_NEW_CAR_SALES(state, value) {
      state.filterNewCarSalesValue = value;
    },
    SET_FILTER_MODEL_OR_DC(state, value) {
      state.filterModelOrDcValue = value;
    },
    SET_EXCEL_BUTTON_STATUS(state, value) {
      state.excelButtonStatus = value;
    },
    SET_CURRENT_AREA_PARAMS(state, value) {
      state.currentAreaParams = value;
    },
    SET_FILTER_SERVICE_TYPE(state, value) {
      state.filterServiceType = value;
    },
    SET_FILTER_ORDER(state, value) {
      state.filterOrder = value;
    },
    SET_FILTER_AMOUNT(state, value) {
      state.filterAmountToShow = value;
    },
    SET_FILTER_REPORT_TYPE(state, value) {
      state.filterReportValue = value;
    },
    SET_ERROR(state, value) {
      state.error = value;
    },
    SET_FILTER_ORDER_NUMBER(state, value) {
      state.filterOrderNumberValue = value;
    },
  },
  actions: {
    saveCurrentUser({ commit }, user) {
      commit("SET_CURRENT_USER", user);
      window.localStorage.user = JSON.stringify(user);
    },
    saveCurrentToken({ commit }, token) {
      commit("SET_CURRENT_TOKEN", token);
      window.localStorage.token = token;
    },
    saveCurrentLang({ commit }, lang) {
      commit("SET_CURRENT_LANG", lang);
      window.localStorage.currentLang = JSON.stringify(lang);
    },
    saveLangMessages({ commit }, langMessages) {
      commit("SET_LANG_MESSAGES", langMessages);
    },
    saveSelectedFilterArea({ commit }, obj) {
      commit("SET_FILTER_AREA_VALUE", obj.value);
      commit("SET_FILTER_AREA_CATEGORY", obj.category);
    },
    resetFiltersAreaComboParams({ commit }) {
      commit("SET_FILTER_AREA_VALUE", null);
      commit("SET_FILTER_AREA_CATEGORY", null);
    },
    saveFilterFYValue({ commit }, obj) {
      commit("SET_FILTER_FY_VALUE", obj.value);
    },
    saveFilterMonthValue({ commit }, obj) {
      commit("SET_FILTER_MONTH_VALUE", obj.value);
    },
    saveFilterIsCumulativeValue({ commit }, obj) {
      commit("SET_FILTER_IS_CUMULATIVE_VALUE", obj.value);
    },
    saveFilterMonthSinceValue({ commit }, obj) {
      commit("SET_FILTER_MONTH_SINCE_VALUE", obj.value);
    },
    saveFilterMonthUpToValue({ commit }, obj) {
      commit("SET_FILTER_MONTH_UP_TO_VALUE", obj.value);
    },
    saveFilterCampaignValue({ commit }, obj) {
      commit("SET_FILTER_CAMPAIGN", obj.value);
    },
    saveFilterModelValue({ commit }, obj) {
      commit("SET_FILTER_MODEL", obj.value);
    },
    saveFilterSinceDateValue({ commit }, value) {
      commit("SET_FILTER_SINCE_DATE", value);
    },
    saveFilterUpToDateValue({ commit }, value) {
      commit("SET_FILTER_UP_TO_DATE", value);
    },
    saveFilterAspmSpmValue({ commit }, value) {
      commit("SET_FILTER_ASPM_SPM", value);
    },
    saveFilterTypeOfContractValue({ commit }, value){
      commit("SET_FILTER_TYPE_OF_CONTRACT", value);
    },
    saveFilterNewCarSalesValue({ commit }, value) {
      commit("SET_FILTER_NEW_CAR_SALES", value);
    },
    saveFilterModelOrDcValue({ commit }, value) {
      commit("SET_FILTER_MODEL_OR_DC", value);
    },
    saveExcelButtonStatus({ commit }, obj) {
      commit("SET_EXCEL_BUTTON_STATUS", obj.value);
    },
    saveCurrentAreaParams({ commit }, value) {
      commit("SET_CURRENT_AREA_PARAMS", value);
    },
    saveFilterServiceType({ commit }, obj) {
      commit("SET_FILTER_SERVICE_TYPE", obj.value);
    },
    saveFilterOrder({ commit }, obj) {
      commit("SET_FILTER_ORDER", obj.value);
    },
    saveFilterAmountToShow({ commit }, obj) {
      commit("SET_FILTER_AMOUNT", obj.value);
    },
    saveFilterReportValue({ commit }, obj) {
      commit("SET_FILTER_REPORT_TYPE", obj.value);
    },
    saveError({ commit }, value){
      commit("SET_ERROR", value);
    },
    saveFilterOrderNumberValue({ commit }, value){
      commit("SET_FILTER_ORDER_NUMBER", value);
    },
  },
  getters: {
    getCurrentUser: (state) => {
      if (!emptyObject.check(state.user)) {
        return state.user;
      } else if (localStorage.user !== undefined) {
        return JSON.parse(localStorage.user);
      } else {
        return null;
      }
    },
    getCurrentToken: (state) => state.token,
    getCurrentUserProfile: (state) => {
      if (!emptyObject.check(state.user)) {
        return state.user.profileId;
      } else if (localStorage.user !== undefined) {
        const user = JSON.parse(localStorage.user);
        return user.profileId;
      } else {
        return null;
      }
    },
    //getCurrentLang: (state) => state.currentLang,
    getCurrentLang: (state) => {
      if (!emptyObject.check(state.currentLang)) {
        return state.currentLang;
      } else if (localStorage.currentLang !== undefined) {
        return JSON.parse(localStorage.currentLang);
      } else {
        return null;
      }
    },
    getLangMessages: (state) => state.langMessages,
    getFiltersAreaComboParams: (state) => {
      const params = {};
      if (state.filterAreaCategoriesResult !== null) {
        state.filterAreaCategoriesResult.forEach((elem) => {
          let prop = state.FILTER_AREA_PROP_CODES[elem];
          params[prop] = "";
        });
        const prop = state.FILTER_AREA_PROP_CODES[state.filterAreaCategory];
        params[prop] = state.filterAreaValue;
        return params;
      } else {
        return false;
      }
    },
    getFilterFYValue: (state) => state.filterFYValue,
    getFilterMonthValue: (state) => state.filterMonthValue,
    getFilterIsCumulativeValue: (state) => state.filterIsCumulativeValue,
    getFilterMonthSinceValue: (state) => state.filterMonthSinceValue,
    getFilterMonthUpToValue: (state) => state.filterMonthUpToValue,
    getFilterCampaignValue: (state) => state.filterCampaignValue,
    getFilterModelValue: (state) => state.filterModelValue,
    getFilterSinceDateValue: (state) => state.filterSinceDateValue,
    getFilterUpToDateValue: (state) => state.filterUpToDateValue,
    getFilterAspmSpmValue: (state) => state.filterAspmSpmValue,
    getFilterTypeOfContractValue: (state) => state.filterTypeOfContractValue,
    getFilterNewCarSalesValue: (state) => state.filterNewCarSalesValue,
    getFilterModelOrDcValue: (state) => state.filterModelOrDcValue,
    getExcelButtonStatus: (state) => state.excelButtonStatus,
    getCurrentAreaParams: (state) => state.currentAreaParams,
    getFilterServiceType: (state) => state.filterServiceType,
    getFilterOrder: (state) => state.filterOrder,
    getFilterAmountToShow: (state) => state.filterAmountToShow,
    getFilterReportValue: (state) => state.filterReportValue,
    getError: (state) => state.error,
    getFilterOrderNumberValue: (state) => state.filterOrderNumberValue,
  },
  modules: {
    concessions,
  },
});
