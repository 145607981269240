<template>
  <div>
    <error-modal ref="errorModal"></error-modal>
    <router-view></router-view>
  </div>
</template>

<script>
import ErrorModal from "@/components/organisms/ErrorModal";

export default {
  name: "App",
  components: {
    ErrorModal,
  },
};
</script>

<style lang="scss">
// Import Main styles for this application

@import "assets/scss/style";
body {
  font-family: NissanBrandRegular;
}
</style>
