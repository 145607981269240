import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
// Containers
const TheContainer = () =>
  import(
    /* webpackChunkName: "container" */ "@/components/containers/TheContainer"
  );

// Views
const page404 = () => import(/* webpackChunkName: "404" */ "@/views/page404");

const Login = () => import(/* webpackChunkName: "login" */ "@/views/Login");

const PasswordRecovery = () =>
  import(
    /* webpackChunkName: "password-recovery" */ "@/views/PasswordRecovery"
  );

const ResetPassword = () =>
  import(/* webpackChunkName: "email-recovery" */ "@/views/ResetPassword");

const PasswordCreation = () =>
  import(
    /* webpackChunkName: "password-creation" */ "@/views/PasswordCreation"
  );

const NotAllowed = () => import("@/views/NotAllowed");

const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard");

const ReferenceNumbers = () =>
  import(
    /* webpackChunkName: "reference-numbers" */ "@/views/basic-reports/ReferenceNumbers"
  );

const ControlPanel = () =>
  import(
    /* webpackChunkName: "control-panel" */ "@/views/basic-reports/ControlPanel"
  );

const PurchasesTracking = () =>
  import(
    /* webpackChunkName: "purchases-tracking" */ "@/views/commercial-area/PurchasesTracking"
  );

const DealerIncentiveEstimation = () =>
  import(
    /* webpackChunkName: "estimated-incentives" */ "@/views/commercial-area/DealerIncentiveEstimation"
  );

const incidentNfit = () =>
  import(
    /* webpackChunkName: "incident-nfit" */ "@/views/marketing-area/incidentNfit"
  );

const UserManager = () =>
  import(
    /* webpackChunkName: "user-manager" */ "@/views/management/UserManager"
  );

const RegisterUser = () =>
  import(
    /* webpackChunkName: "user-manager" */ "@/views/management/RegisterUser"
  );

const NetworkIncentiveEstimation = () =>
  import(
    /* webpackChunkName: "network-estimated-incentives" */ "@/views/commercial-area/NetworkIncentiveEstimation"
  );

const DailyReport = () =>
  import(
    /* webpackChunkName: "daily-report" */ "@/views/nibsa-reports/DailyReport"
  );

const VolumeBonus = () =>
  import(
    /* webpackChunkName: "volume-bonus" */ "@/views/commercial-area/VolumeBonus"
  );

const InvoicingQuery = () =>
  import(
    /* webpackChunkName: "invoicing-query" */ "@/views/management/InvoicingQuery"
  );

const ClosureForecast = () =>
  import(
    /* webpackChunkName: "closure-forecast" */ "@/views/commercial-area/ClosureForecast"
  );

const Configuration = () =>
  import(
    /* webpackChunkName: "configuration" */ "@/views/management/Configuration"
  );

const SetClosureForecast = () =>
  import(
    /* webpackChunkName: "set-closure-forecast" */ "@/views/management/SetClosureForecast"
  );

const RecallCampaigns = () =>
  import(
    /* webpackChunkName: "recall-campaigns" */ "@/views/warranty-area/RecallCampaigns"
  );

const PurchaseDataDetail = () =>
  import(
    /* webpackChunkName: "purchase-data-detail" */ "@/views/commercial-area/PurchaseDataDetail"
  );

const TrackingEwSc = () =>
  import(
    /* webpackChunkName: "tracking-ew-sc" */ "@/views/marketing-area/TrackingEwSc"
  );

const Accessories = () =>
  import(
    /* webpackChunkName: "accessories" */ "@/views/marketing-area/Accessories"
  );

const TrackingDc = () =>
  import(
    /* webpackChunkName: "tracking-dc" */ "@/views/marketing-area/TrackingDc"
  );

  const Documentation = () =>
  import(
    /* webpackChunkName: "documentation" */ "@/views/management/Documentation"
  );
  const LoadManager = () =>
  import(
    /* webpackChunkName: "load-manager" */ "@/views/management/LoadManager"
  );
  const PurchasesAdhoc = () =>
  import(
    /* webpackChunkName: "purchases-adhoc" */ "@/views/management/PurchasesAdhoc"
  );

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: configRoutes(),
});

router.beforeEach((to, from, next) => {
  const loginRoutes = [
    "Login",
    "PasswordRecovery",
    "PasswordCreation",
    "ResetPassword",
    "NotAllowed",
  ];

  // next();

  if (
    !loginRoutes.includes(to.name) &&
    !store.getters.getCurrentToken &&
    !localStorage.token
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;

function configRoutes() {
  return [
    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/recuperar-contrasena",
      name: "PasswordRecovery",
      component: PasswordRecovery,
    },
    {
      path: "/recuperar-contrasenha",
      name: "PasswordRecovery",
      component: PasswordRecovery,
    },
    {
      path: "/creacion-contrasena/:lang",
      name: "PasswordCreation",
      component: PasswordCreation,
    },
    {
      path: "/resetPassword",
      name: "ResetPassword",
      component: ResetPassword,
    },
    {
      path: "/not-allowed",
      name: "NotAllowed",
      component: NotAllowed,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: TheContainer,
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "/informes-basicos",
          redirect: "/informes-basicos/cifras-de-referencia",
          name: "Informes Básicos",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "cifras-de-referencia",
              name: "Cifras de referencia",
              component: ReferenceNumbers,
            },
            {
              path: "cuadro-de-mando",
              name: "Cuadro de mando",
              component: ControlPanel,
            },
          ],
        },
        {
          path: "/area-comercial",
          redirect: "/area-comercial/seguimiento-de-compras-kpis",
          name: "Área Comercial",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "seguimiento-de-compras-kpis",
              name: "Seguimiento de compras & KPI's",
              component: PurchasesTracking,
            },
            {
              path: "estimacion-incentivos",
              name: "Estimación incentivos concesión",
              component: DealerIncentiveEstimation,
            },
            {
              path: "estimacion-incentivos-red",
              name: "Estimación incentivos red",
              component: NetworkIncentiveEstimation,
            },
            {
              path: "bono-volumen",
              name: "Bono Volumen",
              component: VolumeBonus,
            },
            {
              path: "prevision-de-cierre",
              name: "Previsión de cierre",
              component: ClosureForecast,
            },
            {
              path: "detalle-datos-compras",
              name: "Detalle datos compras",
              component: PurchaseDataDetail,
            },
          ],
        },
        {
          path: "/area-marketing",
          redirect: "/area-marketing/informe-altas-en-alias",
          name: "Área Marketing",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "informe-accesorios",
              name: "Informe Accesorios",
              component: Accessories,
            },
            {
              path: "seguimiento-dc",
              name: "Seguimiento DC",
              component: TrackingDc,
            },
            {
              path: "informe-altas-en-alias",
              name: "Informe de Altas en Alias",
              component: incidentNfit,
            },
            {
              path: "seguimiento-ew-sc",
              name: "Seguimiento EW & SC",
              component: TrackingEwSc,
            },
          ],
        },
        {
          path: "/area-garantias",
          redirect: "/area-garantias/recall-campaigns",
          name: "Área Garantías",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "recall-campaigns",
              name: "Recall Campaigns",
              component: RecallCampaigns,
            },
          ],
        },
        {
          path: "/administracion",
          redirect: "/administracion/gestor-de-usuarios",
          name: "Administración",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "gestor-de-usuarios",
              name: "Gestor de usuarios",
              component: UserManager,
            },
            {
              path: "nuevo-usuario",
              name: "Nuevo usuario",
              component: RegisterUser,
            },
            {
              path: "consulta-invoice",
              name: "Consulta Invoice",
              component: InvoicingQuery,
            },
            {
              path: "configuracion",
              name: "Configuración",
              component: Configuration,
            },
            {
              path: "establecer-prevision-de-cierre",
              name: "Establecer previsión de cierre",
              component: SetClosureForecast,
            },
            {
              path: "documentacion",
              name: "Documentación",
              component: Documentation,
            },
            {
              path: "gestor-de-cargas",
              name: "Gestor de cargas",
              component: LoadManager,
            },
            {
              path: "gestor-compras-ad-hoc",
              name: "Gestor compras ad-hoc",
              component: PurchasesAdhoc,
            },
          ],
        },
        {
          path: "/informes-nibsa",
          redirect: "/informes-nibsa/parte-diario",
          name: "Informes Nibsa",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "parte-diario",
              name: "Parte diario",
              component: DailyReport,
            },
          ],
        },
      ],
    },
    //Rutas portuguesas
    {
      path: "/dashboard",
      name: "Dashboard",
      component: TheContainer,
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "/relatorios-basicos",
          redirect: "/relatorios-basicos/valores-de-referencia",
          name: "Relatórios Básicos",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "valores-de-referencia",
              name: "Valores de referência",
              component: ReferenceNumbers,
            },
            {
              path: "scorecard",
              name: "Scorecard",
              component: ControlPanel,
            },
          ],
        },
        {
          path: "/area-comercial",
          redirect: "/area-comercial/seguimento-de-compras-kpis",
          name: "Área Comercial",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "seguimento-de-compras-kpis",
              name: "Seguimento de compras & KPI's",
              component: PurchasesTracking,
            },
            {
              path: "estimativa-incentivos-concessao",
              name: "Estimativa de incentivos concessão",
              component: DealerIncentiveEstimation,
            },
            {
              path: "estimativa-incentivos-rede",
              name: "Estimativa de incentivos de rede",
              component: NetworkIncentiveEstimation,
            },
            {
              path: "bonus-volume",
              name: "Bónus Volume",
              component: VolumeBonus,
            },
            {
              path: "previsao-de-fecho",
              name: "Previsão de fecho",
              component: ClosureForecast,
            },
            {
              path: "detalhe-dados-compras",
              name: "Detalhe dados compras",
              component: PurchaseDataDetail,
            },
          ],
        },
        {
          path: "/area-marketing",
          redirect: "/area-marketing/informacao-incidencias-nfit",
          name: "Área Marketing",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "relatorio-de-accesorios",
              name: "Relatório de acessórios",
              component: Accessories,
            },
            {
              path: "informacao-incidencias-nfit",
              name: "Informação Incidências NFIT",
              component: incidentNfit,
            },
            {
              path: "rastreamento-ew-sc",
              name: "Rastreamento EW & SC",
              component: TrackingEwSc,
            },
            {
              path: "rastreamento-dc",
              name: "Rastreamento DC",
              component: TrackingDc,
            },
          ],
        },
        {
          path: "/area-de-garantia",
          redirect: "/area-de-garantia/recall-campaigns",
          name: "Área de Garantia",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "recall-campaigns",
              name: "Recall Campaigns",
              component: RecallCampaigns,
            },
          ],
        },
        {
          path: "/administracao",
          redirect: "/administracao/gestor-de-usuarios",
          name: "Administraçao",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "gestor-de-usuarios",
              name: "Gestor de usuários",
              component: UserManager,
            },
            {
              path: "novo-user",
              name: "Novo user",
              component: RegisterUser,
            },
            {
              path: "consulta-invoice",
              name: "Consulta Invoice",
              component: InvoicingQuery,
            },
            {
              path: "configuracao",
              name: "Configuração",
              component: Configuration,
            },
            {
              path: "estabelecer-previsao-de-fecho",
              name: "Estabelecer previsão de fecho",
              component: SetClosureForecast,
            },
            {
              path: "gestor-de-cargas",
              name: "Gestor de cargas",
              component: LoadManager,
            },
          ],
        },
        {
          path: "/relatorios-nibsa",
          redirect: "/relatorios-nibsa/relatorio-diario",
          name: "Relatórios Nibsa",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "relatorio-diario",
              name: "Relatório diário",
              component: DailyReport,
            },
          ],
        },
      ],
    },
    {
      path: "/art-dmr",
      beforeEnter() {
        window.location.replace("http://art-dmr.com");
      },
    },
    {
      path: "/:catchAll(.*)",
      name: "page404",
      component: page404,
    },
  ];
}
