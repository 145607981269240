import axios from "axios";
import store from "@/store/index";
import Logout from "@/helpers/Logout";
import languages from "../languages/languages";

const urlApi = process.env["VUE_APP_URL_API"];

const request = () => {
  const currentToken = store.getters.getCurrentToken
    ? store.getters.getCurrentToken
    : localStorage.token;
  if (currentToken) {
    return axios.create({
      baseURL: urlApi,
      headers: {
        // eslint-disable-next-line prettier/prettier
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "true",
        // eslint-disable-next-line prettier/prettier
        "Authorization": `Bearer ${currentToken}`,
      },
    });
  } else {
    return false;
  }
};

const testPost = async (serviceURL, params) => {
  return await request()
    .post(serviceURL, params)
    .then((response) => response)
    .catch((error) => {
      if (error.response.status === 401) {
        return Logout();
      } else {
        return error.response.status;
      }
    });
};

const sendPost = async (serviceURL, params, headers) => {
  try {
    return await request().post(serviceURL, params, headers);
  } catch (e) {
    // const errorCode = e.response.status;
    // store.dispatch("saveError", errorCode);
    return Logout();
  }
};

const sendGet = async (serviceURL) => {
  try {
    return await request().get(serviceURL);
  } catch (e) {
    return Logout();
  }
};

const sendDelete = async (serviceURL) => {
  try {
    return await request().delete(serviceURL);
  } catch (e) {
    return Logout();
  }
};

const downloadFileRequest = async (fileUrl) => {
  const response = await request()
    .get(fileUrl, {
      responseType: "blob",
      // timeout: 30000,
    })
    .then((response) => response);
  // console.log(response.config.url);
  return response;
};

export default {
  sendPost: sendPost,
  sendGet: sendGet,
  sendDelete: sendDelete,
  testPost: testPost,
  downloadFileRequest: downloadFileRequest,
};
