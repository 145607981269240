export default {
  texts(lang) {
    const messages = {
      es: {
        common: {
          and: "y",
          error: "Error",
          national: "Nacional",
          regional: "Regional",
          table: "Tabla",
          tables: "Tablas",
          print: "Imprimir",
          businessName: "Razón social",
          areaCategories: {
            concessionCode: "Concesión",
            countryCode: "País",
            regionalCode: "Regional",
            aspm: "ASPM",
          },
          released: "Generadas",
          pending: "Pendiente",
          pending2: "Pendientes",
          donwload: "Descargar",
          donwloading: "Descargando",
          thereWasAnError: "Hubo un error",
          failed: "Fallidas",
        },
        errors: {
          "000":
            "Ha ocurrido un error, inténtalo de nuevo más tarde o ponte en contacto con el administrador.",
          400: "Ha ocurrido un error, inténtalo de nuevo más tarde o ponte en contacto con el administrador.",
          404: "Ha ocurrido un error, inténtalo de nuevo más tarde o ponte en contacto con el administrador.",
          500: "Ha ocurrido un error, inténtalo de nuevo más tarde o ponte en contacto con el administrador.",
          801: "Error de identificación",
          802: "No se ha podido crear. Los datos enviados son incorrectos",
          803: "No se ha podido crear porque ya existe un usuario con este email",
          810: "Error de identificación",
          811: "Error de identificación",
          812: "No se ha podido establecer la contraseña",
          813: "No se ha podido establecer la contraseña",
          814: "Este email no está en nuestra Base de Datos",
          815: "Este email no está en nuestra Base de Datos",
          "011": "Este email no está en nuestra Base de Datos",
          "021": "Error en la creación de la contraseña",
          "031": "Error de recuperación contraseña",
          906: "Los datos del archivo seleccionado no son correctos.",
          'xlsx': "Archivo no compatible. Sólo se admiten archivos .xlsx.",
          '10MB': "Sólo se admiten archivos de hasta 10MB.",
        },
        validation: {
          required: "Campo obligatorio",
          emailError: "E-mail incorrecto",
          passwordError: "Formato de contraseña no válido",
          passwordsNotMatch: "Las contraseñas no coinciden",
          filterAreaCombo: "Selecciona algún criterio de área",
          incorrectPeriod: "Periodo no válido",
          numberRequired: "Inserta un número",
        },
        passwordRecovery: {
          title: "RECUPERA TU CONTRASEÑA",
          instructions: "Introduzca su correo para restablecer su contraseña.",
          emailPlaceholder: "EMAIL",
          successMessage:
            "Recibirá un correo con las instrucciones y el link para restablecer su contraseña",
          button: "RECUPERAR",
        },
        passwordCreation: {
          title: "ESTABLECE TU CONTRASEÑA",
          instructions:
            "Tu contraseña debe tener una longitud mínima de 8 carácteres e incluir como mínimo una mayúscula, una minúscula y un número.",
          passwordPlaceholder: "CONTRASEÑA",
          passwordRepeatPlaceholder: "REPITE TU CONTRASEÑA",
          button: "ESTABLECER",
          passwordCreated:
            "Tu contraseña se ha creado correctamente. Accede a la Plataforma.",
          buttonLogin: "LOGIN",
        },
        filters: {
          area: "Área",
          countriesPlaceholder: "Escoger área",
          regionsPlaceholder: "Escoger regional",
          concessionsPlaceholder: "Escoger concesión",
          aspmsPlaceholder: "Escoger ASPM",
          reportsPlaceholder: "Escoger tipo de datos",
          months: {
            1: "enero",
            2: "febrero",
            3: "marzo",
            4: "abril",
            5: "mayo",
            6: "junio",
            7: "julio",
            8: "agosto",
            9: "septiembre",
            10: "octubre",
            11: "noviembre",
            12: "diciembre",
          },
          monthsArray: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
          weekDaysArray: {
            long: [
              "Domingo",
              "Lunes",
              "Martes",
              "Miércoles",
              "Jueves",
              "Viernes",
              "Sábado",
            ],
            short: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
          },
          isNoCumulative: "Valor mensual",
          isCumulative: "Valor acumulado",
          cumulativeOrNot: "Mensual / Acumulado",
          since: "Desde",
          upTo: "hasta",
          period: "Periodo",
          campaign: "Campaña",
          monthlyDetail: "Detalle mensual",
          concessionGroup: "Agrupación de Concesiones",
          ew: "Extensión de Garantía (EW)",
          sc: "Service Contract (SC)",
          typeOfContract: "Tipo de contrato",
          all: "Todos",
          modelOrDc: "Modelo / DC",
          model: "Modelo",
          serviceType: "Tipo de servicio",
          select: "Escoger",
          invoicing: "Facturación",
          report: {
            obj_parte_diario: "Objetivos parte diario ",
            compras_parte_diario: "Compras parte diario",
            obj_bono_volumen: "Objetivos de Bono Volumen",
            obj_accesorios: "Objetivo de Accesorios",
            parque: "Parque",
            holidays: "Días festivos",
            oil_program: "Programación aceite",
            recall_campaign: "Referencias Recall Campaign",
            reman_va: "Referencias Reman + VA",
            acc_ref_model: "Referencias de accesorios y Modelos",
            altas_alias: "Altas en Alias",
            descuentos_pedidos: "Descuentos de pedidos ",
          }
        },
        header: {
          welcome: "Bienvenido",
          lastInvoiceDate: "Fecha último invoice cargado",
        },
        tables: {
          filter: "Filtra la tabla",
          quarterlyTotal: "TOTAL TRIMESTRE",
          results: "Resultados",
          search: "Buscar",
          noResults: "No hay resultados para esta búsqueda",
          noItems: "No hay datos",
          export: "Exportar",
          print: "Imprimir",
          table: "Tabla",
          tables: "Tablas",
          noDownloads: "No hay descargas",
        },
        ranking: {
          country: "Media nacional",
          regional: "Valor regional",
          aspm: "Valor aspm",
          concession: "Valor concesionario",
          concession2: "Ranking concesionario",
          completionAbbr: "Cumplim.",
        },
        controlPanel: {
          invoiceNoCumulative: "Total compras: Facturación mensual",
          invoiceCumulative: "Total compras: Facturación acumulada",
        },
        products: {
          volumeBonus: "Bono Volumen",
          oil: "Aceite",
          tyres: "Neumáticos",
          paintAndConsumables: "Pintura y Consumibles",
          paintAndCons: "Pintura y Cons.",
          accessories: "Accesorios",
          piecesRemanAndValueAdvantage: "Piezas Reman + Value Advantage",
          remanAndVa: "Reman + VA",
          byCodeList: {
            BV: "Bono Volumen",
            BV85: "Bono Volumen 85%",
            T05: "Aceite",
            T03: "Neumáticos",
            BPC: "Pintura y Consumibles",
            ACC: "Accesorios",
            REMANVA: "Reman + VA",
          },
        },
        areaByCode: {
          ESP: "España",
          PRT: "Portugal",
          IBR: "Iberia",
          CN: "Centro-norte",
          NE: "Noreste",
          PT: "Portugal",
          SE: "Sureste",
        },
        referenceNumbers: {
          title: "Cifras de referencia",
          month: "Mes",
          range: "Parque",
        },
        purchasesTracking: {
          title: "Seguimiento de compras & KPI's",
          table1: "Tabla 1",
          table2: "Tabla 2",
          concept: "Concepto",
          targetPurchases: "Objetivo",
          purchases: "Compras",
          purchasesCompletion: "Cumpl.",
          purchasesComparative: "Compras vs. año ant.",
          purchasesUioAverage: "UIO",
          purchasesUioAverageComparative: "UIO vs. año ant.",
          nationalPurchasesAverage: "UIO nacionales",
          nationalPurchasesAverageComparative: "UIO nacionales vs. año ant.",
          uniPar: "Unipar",
          nationalUniPar: "Unipar nacional",
          purchasesQuantity: "Uni. compra",
          ncs: "NCS",
        },
        dealerIncentiveEstimation: {
          totalPurchases: "Compras reales",
          percentageOverTotal: "Porcentaje sobre el Total Compras",
          incentives: "Incentivos",
          note1:
            "Los resultados de incentivos estimados mostrados en este informe son de carácter orientativo para el seguimiento y la previsión del concesionario.",
          note2:
            "La comunicación de los incentivos reales a abonar se realizará por las vías habituales.",
          note3:
            "Como se indica en la política comercial, el incumplimiento de algunas de las condiciones básicas implica no percibir el bono correspondiente.",
          description: "Bono",
          targetPurchases: "Objetivos",
          purchasesCount: "Compras estimadas cuentan",
          purchasesCharge: "Compras estimadas cobran",
          completion: "Cumplimentación",
          incentive: "Incentivo Estimado",
          maxIncentive: "A 100% objetivo",
          incentiveDiff: "Diferencia",
          basicConditions: "Condiciones básicas",
          complies: "Cumple",
          doNotComplies: "No cumple",
        },
        home: {
          purchases: "Compras",
          currentMonthPurchases: "Compras mes en curso",
          cumulativeTotal: "Total acumulado",
          details: "Detalles",
          estimatedPurchases: "Compras estimadas",
          target: "Objetivo",
          completion: "Cumplimentación",
          curMonthComplLevel: "Nivel cumplimentación mes en curso",
        },
        incidentNfit: {
          title: "Informe de Altas en Alias",
          ref: "Referencia pieza",
          nfitId: "Código incidencia NFIT",
          nfitDate: "Fecha NFIT",
          status: "Estatus",
          statusDate: "Fecha estatus",
        },
        userManager: {
          title: "Gestor de usuarios",
          listTitle: "Lista de usuarios",
          name: "Nombre",
          email: "Email",
          language: "Idioma",
          spanish: "Español",
          portuguese: "Portugués",
          profile: "Perfil",
          concessionCode: "Código Concesión",
          businessName: "Nombre Concesión",
          deleteUser: "Eliminar Usuario",
          profileNames: {
            "-20": "Superadministrador",
            "-10": "Administrador",
            0: "Nibsa",
            10: "ASPM",
            20: "Administrador Concesión",
            21: "Usuario Concesión",
            30: "Usuario NFIT",
          },
          newUser: "Nuevo usuario",
          submitButton: "Crear",
          responseOK: (name, email) => {
            return `Se ha creado el nuevo usuario ${name}. Se ha enviado un email al correo  ${email} para que pueda establecer la contraseña de acceso a ART.`;
          },
        },
        networkIncentiveEstimation: {
          title: "Estimación de incentivos red",
        },
        dailyReport: {
          title: "Parte diario",
          daysInvoiced: "Días facturados",
          workingDays: "Días laborables",
          warningTitle: "¿Estás seguro?",
          // Complete warning default message example: "El nuevo valor para días laborables se almacenará"
          warningDefaultMessage1: "El nuevo valor para",
          warningDefaultMessage2: "se almacenará",
          // Complete warning custom message example: "El nuevo valor para días laborables se establecerá en 22 días"
          warningMessage1: "El nuevo valor para",
          warningMessage2: "se establecerá en",
          warningMessage3: "días",
          customColumnHeader: "Cabecera Reforecast",
          variation: "Variación",
          monthlyCompletion: "Cumpl. mes",
          dailyCompletion: "Cumpl. al día",
          tableHeaders: {
            key0: "Miles de euros",
            currentPurchases: "Real",
          },
          dailyAverage: "Media diaria",
        },
        closureForecast: {
          title: "Previsión de cierre",
        },
        volumeBonusTable: {
          month: "Mes",
          concessionCode: "Código concesión",
          businessName: "Razón social",
          targetPurchases: "Objetivo",
          purchasesCount: "Compras cuentan",
          purchasesCharge: "Compras cobran",
          completion: "Cumplimentación",
          incentive: "Incentivo / Incentivo estimado",
          purchasesCountOriginal: "Compras cuentan sin descuentos",
          purchasesChargeOriginal: "Compras cobran sin descuentos",
          // purchasesToCountWithoutDiscount: "Compras cuentan sin descuentos",
          // purchasesToPayWithoutDiscount: "Compras cobran sin descuentos",
        },
        closureForecastTable: {
          countryCode: "País",
          regionalCode: "Regional",
          aspm: "ASPM",
          concessionCode: "Código",
          businessName: "Razón social",
          updatedAt: "Fecha actualización",
          purchases: "Compras cuentan",
          targetPurchases: "Objetivo",
          prevision: "Previsión c. cuentan",
          previsionByAspm: "Previsión ASPM",
          previsionCompletion: "Cumplimentación previsión",
          previsionByAspmCompletion: "Cumplimentación ASPM",
          previsionUIO: "Previsión €/UIO",
          previsionByAspmUIO: "PREVISIÓN €/UIO ASPM",
        },
        recallCampaignsTable: {
          title: "Recall Campaigns",
          showCancelled: "Mostrar canceladas",
          createdAt: "Fecha alta campaña",
          mio: "MIO",
          campaignType: "Tipo de campaña",
          campaignCode: "PFP",
          campaignModel: "Modelo",
          campaignDesc: "Descripción campaña",
          affectedNum: "VINs afectados / consultados",
          repairedNum: "VINs reparados",
          exportedNum: "VINs exportados / desguazados",
          completion: "% cumplimentación",
          pendingNum: "VINs pendientes",
          isCancelled: "Cancelada",
        },
        purchaseDataDetail: {
          title: "Detalle datos compras",
          dealer: "Concesionario",
          period: "Periodo",
          purchasesCount: "Compras cuentan",
          purchases: "Compras",
          urgentValue: "Valor urgente",
        },
        trackingEwSc: {
          title: "Informe Extensión de Garantía Nissan 5* Y Mantenimiento+",
          totalNCS: "NCS TOTAL",
          totalSC: "SC TOTAL",
          scCamp: "SC CAM.",
          scPrivate: "SC PRIVADO",
          completion: "% CUMPLIM.",
          totalScInvoice: "FACT. TOTAL SC",
          totalScPrivateInvoice: "FACT. SC PRIVADO",
          totalEW: "EW TOTAL",
          ewCamp: "EW CAM.",
          ewPrivate: "EW PRIVADO",
          totalEwInvoice: "FACT. TOTAL EW",
          totalEwPrivateInvoice: "FACT. EW PRIVADO",
          ncs: (str) => `TOTAL ${str === "ALL" ? "TODOS" : str}`,
          totalValue: (str) => `${str} TOTAL`,
          campaignValue: (str) => `${str} CAM.`,
          privateValue: (str) => `${str} PRIVADO`,
          totalInvoiced: (str) => `FACT. TOTAL ${str}`,
          totalPrivateInvoiced: (str) => `FACT. ${str} PRIVADO`,
        },
        accessories: {
          title: "Informe Accesorios",
        },
        trackingDc: {
          title: "Informe de DC",
          dcFamily: "Familia DC",
          dcDescription: "Descripción DC",
          invoicing: "Facturación",
          previousYearInvoicing: "Facturación aa",
          efficiency: "Eficiencia",
        },
        purchasesAdhoc: {
          title: "Compras ad-hoc",
          product: "Producto",
          purchases: "Compras adhoc",
          amount: "Cantidad",
          donwloadImportTemplate: "Descargar plantilla importación",
        },
      },
      pt: {
        common: {
          and: "y",
          error: "Erro",

          national: "Nacional",

          regional: "Regional",
          businessName: "Nome Concessão",
          areaCategories: {
            concessionCode: "Concessão",
            countryCode: "País",
            regionalCode: "Regional",
            aspm: "ASPM",
          },
          released: "Generadas",
          pending: "Pendente",
          pending2: "Pendentes",
          donwload: "Baixar",
          donwloading: "Descarregando",
          thereWasAnError: "Ocorreu um erro",
          failed: "Fracassado",
        },
        errors: {
          "000":
            "Ocorreu um erro, tente novamente mais tarde ou contacte o administrador.",
          400: "Ocorreu um erro, tente novamente mais tarde ou contacte o administrador.",
          404: "Ocorreu um erro, tente novamente mais tarde ou contacte o administrador.",
          500: "Ocorreu um erro, tente novamente mais tarde ou contacte o administrador.",
          801: "Erro de identificação",

          802: "No se ha podido crear. Los datos enviados son incorrectos",

          803: "No se ha podido crear porque ya existe un usuario con este email",
          810: "Erro de identificação",
          811: "Erro de identificação",
          812: "Não foi possível definir a senha",
          813: "Não foi possível definir a senha",
          814: "Este e-mail não se encontra na nossa Base de Dados",
          815: "Este e-mail não se encontra na nossa Base de Dados",
          "011": "Este e-mail não se encontra na nossa Base de Dados",
          "021": "Erro de criação de senha",
          "031": "Erro de recuperação de senha",
          906: "Os dados do arquivo selecionado não estão corretos.",
          'xlsx': "Arquivo não suportado. Somente arquivos .xlsx são suportados.",
          '10MB': "Somente arquivos de até 10 MB são suportados.",
        },
        validation: {
          required: "Campo obrigatório",
          emailError: "E-mail inválido",
          passwordError: "Formato de senha inválido",
          passwordsNotMatch: "As senhas não coincidem",
          filterAreaCombo: "Seleccione um critério de área",
          incorrectPeriod: "Período inválido",
          numberRequired: "Inserir um número",
        },
        passwordRecovery: {
          title: "RECUPERE SUA CONTRASENHA",
          instructions: "Insira seu e-mail para redefinir sua contrasenha.",
          emailPlaceholder: "E-MAIL",
          successMessage:
            "Você receberá um e-mail com as instruções e o link para redefinir sua contrasenha",
          button: "RECUPERAR",
        },
        passwordCreation: {
          title: "COLOQUE SUA SENHA",
          instructions:
            "Sua senha deve ter pelo menos 8 caracteres e incluir pelo menos uma maiúscula, uma minúscula e um número.",
          passwordPlaceholder: "SENHA",
          passwordRepeatPlaceholder: "REPITA SUA SENHA",
          button: "CONFIGURAÇÃO",
          passwordCreated:
            "A sua senha foi criada com sucesso. Aceder à plataforma.",
          buttonLogin: "LOGIN",
        },
        filters: {
          area: "Área",
          countriesPlaceholder: "Escolha a área",
          regionsPlaceholder: "Escolha regional",
          concessionsPlaceholder: "Escolha concessão",
          aspmsPlaceholder: "Escolha ASPM",
          reportsPlaceholder: "Escolha tipo de datos",
          months: {
            1: "Janeiro",
            2: "Fevereiro",
            3: "Março",
            4: "Abril",
            5: "Maio",
            6: "Junho",
            7: "Julho",
            8: "Agosto",
            9: "Setembro",
            10: "Outubro",
            11: "Novembro",
            12: "Dezembro",
          },
          monthsArray: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          weekDaysArray: {
            long: [
              "Domingo",
              "Segunda-feira",
              "Terça-feira",
              "Quarta-feira",
              "Quinta-feira",
              "Sexta-feira",
              "Sábado",
            ],
            short: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          },
          isNoCumulative: "Valor mensal",
          isCumulative: "Valor acumulado",
          cumulativeOrNot: "Mensal / Acumulado",
          since: "De",
          upTo: "para",
          period: "Período",
          campaign: "Campanha",
          monthlyDetail: "Detalhe mensal",
          concessionGroup: "Agrupamento de Concessões",
          ew: "Extensão da garantia (EW)",
          sc: "Service Contract (SC)",
          typeOfContract: "Tipo de contrato",
          all: "Todos",
          modelOrDc: "Modelo / DC",
          model: "Modelo",
          serviceType: "Service Type",
          select: "Escolha",
          invoicing: "Facturación",
          report: {
            obj_parte_diario: "Objetivos parte diario ",
            compras_parte_diario: "Compras parte diario",
            obj_bono_volumen: "Objetivos de Bono Volumen",
            obj_accesorios: "Objetivo de Accesorios",
            parque: "Parque",
            holidays: "Días festivos",
            oil_program: "Programación aceite",
            recall_campaign: "Referencias Recall Campaign",
            reman_va: "Referencias Reman + VA",
            acc_ref_model: "Referencias de accesorios y Modelos",
            altas_alias: "Altas en Alias",
            descuentos_pedidos: "Descuentos de pedidos ",
          }
        },
        // Encabezado
        header: {
          welcome: "Bem-vindo",
          lastInvoiceDate: "Data último invoice carregado",
        },
        // Tablas en general
        tables: {
          filter: "Filtrar a tabela",
          quarterlyTotal: "TOTAL TRIMESTRAL",
          results: "Resultados",
          search: "Pesquisa",
          noResults: "Não há resultados para esta pesquisa",
          noItems: "Sem dados",
          export: "Exportar",
          print: "Imprimir",
          table: "Tabela",
          tables: "Tabelas",
          noDownloads: "Sem downloads",
        },
        // Cuadro de mando
        ranking: {
          country: "Média nacional",
          regional: "Média regional",
          aspm: "Valor aspm",
          concession: "Valor concessionário",
          concession2: "Ranking concessionário",
          completionAbbr: "Realização",
        },
        // Cuadro de mando
        controlPanel: {
          invoiceNoCumulative: "Total compras: faturação mensal",
          invoiceCumulative: "Total compras: facturação acumulada",
        },
        // Aparecen en Home, Cuadro de mando, Estimación incentivos concesión, etc.
        products: {
          volumeBonus: "Bónus Volume",
          oil: "Óleo",
          tyres: "Pneus",
          paintAndConsumables: "Pintura e Consumíveis",
          paintAndCons: "Pintura e Cons.",
          accessories: "Acessórios",
          piecesRemanAndValueAdvantage: "Partes Reman + Value Advantage",
          remanAndVa: "Reman + VA",
          byCodeList: {
            BV: "Bónus Volume",
            BV85: "Bónus Volume 85%",
            T05: "Óleo",
            T03: "Pneus",
            BPC: "Pintura e Consumíveis",
            ACC: "Acessórios",
            REMANVA: "Reman + VA",
          },
        },
        areaByCode: {
          ESP: "Espanha",
          PRT: "Portugal",
          IBR: "Iberia",
          CN: "Centro-norte",
          NE: "Noreste",
          PT: "Portugal",
          SE: "Sudeste",
        },
        // Cifras de referencia
        referenceNumbers: {
          title: "Valores de referência",
          month: "Mês",
          range: "Parque",
        },
        // Seguimiento de Compras y KPI's
        purchasesTracking: {
          title: "Seguimento de compras & KPI's",
          table1: "Tabela 1",
          table2: "Tabela 2",
          concept: "Conceito",
          targetPurchases: "objetivo",
          purchases: "Compras",
          purchasesCompletion: "Realização",
          purchasesComparative: "Compras vs. ano ant.",
          purchasesUioAverage: "UIO",
          purchasesUioAverageComparative: "UIO vs. ano ant.",
          nationalPurchasesAverage: "UIO nacionais",
          nationalPurchasesAverageComparative: "UIO nacionais vs. ano ant.",
          uniPar: "Unipar",
          nationalUniPar: "Unipar nacional",
          purchasesQuantity: "Uni. compra",
          ncs: "NCS",
        },
        // Estimación Incentivos Concesión
        dealerIncentiveEstimation: {
          totalPurchases: "Compras reais",
          percentageOverTotal: "Percentagem do total Compras",
          incentives: "Incentives",
          note1:
            "Os resultados de incentivos estimados mostrados neste relatório são de carácter orientativo para o seguimento e a previsão do concessionário.",
          note2:
            "A comunicação dos incentivos reais a pagar será realizada pelos meios habituais.",
          note3:
            "Como indicado na política comercial, o incumprimento de algumas condições básicas pode implicar a não atribuição da bonificação correspondente.",
          description: "Bónus",
          targetPurchases: "objetivos",
          purchasesCount: "Compras estimadas contam",
          purchasesCharge: "Compras estimadas cobram",
          completion: "Realização",
          incentive: "Incentivo Estimado",
          maxIncentive: "A 100% objetivo",
          incentiveDiff: "Diferença",
          basicConditions: "Condições básicas",
          complies: "Cumpre",
          doNotComplies: "Não cumpre",
        },
        // Dashboard, escritorio, home
        home: {
          purchases: "Compras",
          currentMonthPurchases: "Compras mês corrente",
          cumulativeTotal: "Total acumulado",
          details: "Detalhes",
          estimatedPurchases: "Compras estimadas",
          target: "Objetivo",
          completion: "Realização",
          curMonthComplLevel: "Nível realização mês actual",
        },
        // Informe de altas en Alias
        incidentNfit: {
          title: "Relatório de incidências em Alias",
          ref: "Referência da peça",
          nfitId: "Código incidência NFIT",
          nfitDate: "Data NFIT",
          status: "Status",
          statusDate: "Data status",
        },

        // Gestor de usuarios
        userManager: {
          title: "Gestor de users",
          listTitle: "Lista de users",
          name: "Nome",
          email: "Email",
          language: "Idioma",
          spanish: "Espanhol",
          portuguese: "Português",
          profile: "Perfil",
          concessionCode: "Código de concessão",
          businessName: "Nome Concessão",
          deleteUser: "Eliminar User",
          profileNames: {
            "-20": "Superadministrador",
            "-10": "Administrador",
            0: "Nibsa",
            10: "ASPM",
            20: "Administrador Concessão",
            21: "User Concessão",
            30: "User NFIT",
          },
          newUser: "Novo user",
          submitButton: "Criar",
          responseOK: (name, email) => {
            return `Foi criado o novo user ${name}. Foi enviado um e-mail para  ${email} para que possa definir a sua password de acesso à ART.`;
          },
        },
        networkIncentiveEstimation: {
          title: "Estimativa de incentivo de rede",
        },
        dailyReport: {
          daysInvoiced: "Dias facturados",
          workingDays: "Dias úteis",
          warningTitle: "Está seguro?",
          // Complete warning default message example: "El nuevo valor para días laborables se almacenará"
          warningDefaultMessage1: "O novo valor para",
          warningDefaultMessage2: "será armazenado",
          // Complete warning custom message example: "El nuevo valor para días laborables se establecerá en 22 días"
          warningMessage1: "O novo valor para os",
          warningMessage2: "será fixado em",
          warningMessage3: "dias",
          customColumnHeader: "Header Reforecast",
          variation: "Variação",
          monthlyCompletion: "Realiz. mes",
          dailyCompletion: "Realiz. dia",
          tableHeaders: {
            key0: "Milhares de euros",
            currentPurchases: "Real",
          },
          dailyAverage: "Média diária",
          customValues: "Valores personalizados",
        },
        closureForecast: {
          title: "Previsão de fecho",
        },
        volumeBonusTable: {
          month: "Mês",
          concessionCode: "Código de concessão",
          businessName: "Nome Concessão",
          targetPurchases: "Objetivo",
          purchasesCount: "Compras contam",
          purchasesCharge: "Compras cobram",
          completion: "Realização",
          incentive: "Incentivo / Incentivo estimado",
          purchasesCountOriginal: "Compras contam sem descontos",
          purchasesChargeOriginal: "Compras cobram sem descontos",
          // purchasesToCountWithoutDiscount: "Compras contam sem descontos",
          // purchasesToPayWithoutDiscount: "Compras cobram sem descontos",
        },
        closureForecastTable: {
          countryCode: "País",
          regionalCode: "Regional",
          aspm: "ASPM",
          concessionCode: "Código",
          businessName: "Nome da empresa",
          updatedAt: "Data de actualização",
          purchases: "As compras contam",
          targetPurchases: "Objetivo",
          prevision: "Previsão c. cuentan",
          previsionByAspm: "Previsão ASPM",
          previsionCompletion: "Previsão de conclusão",
          previsionByAspmCompletion: "Cumprimento da ASPM",
          previsionUIO: "Previsão €/UIO",
          previsionByAspmUIO: "PREVISÃO €/UIO ASPM",
        },
        recallCampaignsTable: {
          title: "Recall Campaigns",
          showCancelled: "Mostrar canceladas",
          createdAt: "Data registo campanha",
          mio: "MIO",
          campaignType: "Tipo de campanha",
          campaignCode: "PFP",
          campaignModel: "Modelo",
          campaignDesc: "Descrição campanha",
          affectedNum: "VINs afectados / consultados",
          repairedNum: "VINs reparados",
          exportedNum: "VINs exportados / desmantelados",
          completion: "% enchimento",
          pendingNum: "VINs pendentes",
          isCancelled: "Cancelada",
        },
        purchaseDataDetail: {
          title: "Detalhe dados compras",
          dealer: "Concessionário",
          period: "Período",
          purchasesCount: "Compras contam",
          purchases: "Compras",
          urgentValue: "Valor urgente",
        },
        trackingEwSc: {
          title: "Informe Extensión de Garantía Nissan 5* Y Mantenimiento+",
          totalNCS: "NCS TOTAL",
          totalSC: "SC TOTAL",
          scCamp: "SC CAM.",
          scPrivate: "SC PRIVADO",
          completion: "% CUMPLIM.",
          totalScInvoice: "FACT. TOTAL SC",
          totalScPrivateInvoice: "FACT. SC PRIVADO",
          totalEW: "EW TOTAL",
          ewCamp: "EW CAM.",
          ewPrivate: "EW PRIVADO",
          totalEwInvoice: "FACT. TOTAL EW",
          totalEwPrivateInvoice: "FACT. EW PRIVADO",
          ncs: (str) => `TOTAL ${str === "ALL" ? "TODOS" : str}`,
          totalValue: (str) => `${str} TOTAL`,
          campaignValue: (str) => `${str} CAM.`,
          privateValue: (str) => `${str} PRIVADO`,
          totalInvoiced: (str) => `FACT. TOTAL ${str}`,
          totalPrivateInvoiced: (str) => `FACT. ${str} PRIVADO`,
        },
        accessories: {
          title: "Relatório de Acessórios",
        },
        trackingDc: {
          title: "Relatório DC",
          dcFamily: "Família DC",
          dcDescription: "Descrição DC",
          invoicing: "Facturação",
          efficiency: "Eficiência", //modificar nombre variable. Eficiencia es % vs. media nacional
          previousYearInvoicing: "Facturaçao aa",
        },
        purchasesAdhoc: {
          title: "Compras ad-hoc",
          product: "Produto",
          purchases: "Compras adhoc",
          amount: "Quantia",
          donwloadImportTemplate: "Descargar plantilla importación",
        },
      },
    };
    if (typeof lang === "undefined") {
      return messages;
    } else {
      return messages[lang];
    }
  },
};
