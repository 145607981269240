import Api from "@/services/Api";

export const concessions = {
  namespaced: true,
  state: {
    concessionList: null,
  },
  mutations: {
    SET_CONCESSION_LIST(state, values) {
      state.concessionList = values;
    },
  },
  actions: {
    async concessionList({ commit }) {
      const result = await Api.sendGet("sys/filter/concessions");
      commit("SET_CONCESSION_LIST", result.data.data);
      window.localStorage.setItem(
        "concessionList",
        JSON.stringify(result.data.data)
      );
    },
  },
  getters: {
    concessionList: (state) => {
      if (state.concessionList) {
        return state.concessionList;
      } else if (window.localStorage.getItem("concessionList")) {
        return JSON.parse(window.localStorage.getItem("concessionList"));
      } else {
        return null;
      }
    },
  },
};
